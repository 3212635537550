<template>
  <div>
    <div class="top">
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin-right: 15px"
        @click="openDialog"
        >新增</el-button
      >
      <el-input
        placeholder="请输入手机号"
        style="width: 180px; margin: 8px 0"
        v-model="queryList.term"
        clearable
        @input="inputText"
      ></el-input>
      <el-button
        @click="changeCondition"
        icon="el-icon-search"
        style="margin-right: 15px"
        type="primary"
        >搜索
      </el-button>
      <el-select
        style="width: 130px; margin: 8px 0"
        @change="changeCondition"
        clearable
        placeholder="公司状态"
        v-model="queryList.authStatus"
      >
        <el-option
          :key="item.id"
          :label="item.name"
          :value="item.id"
          v-for="item in optionState"
        >
        </el-option>
      </el-select>
    </div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :height="tableHeight"
    >
      <el-table-column label="入驻手机号" prop="phone"> </el-table-column>
      <el-table-column label="入驻公司名" prop="companyName">
        <template slot-scope="scope">
          <div>
            {{ scope.row.companyName ? scope.row.companyName : "-" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="公司状态" prop="authStatus">
        <template slot-scope="scope">
          <div v-if="scope.row.authStatus == 0">
            认证失败:{{ scope.row.failMsg }}
          </div>
          <div v-else-if="scope.row.authStatus == -1">待注册</div>
          <div v-else-if="scope.row.authStatus == 1">已提交认证</div>
          <div v-else-if="scope.row.authStatus == 2">已注册</div>
          <div v-else-if="scope.row.authStatus == 3">认证成功</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="跟进记录"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="name" label="操作">
        <template slot-scope="scope"
          ><el-button size="small" type="primary" @click="Look(scope.row.id)"
            >添加跟进记录</el-button
          ></template
        >
      </el-table-column>
      <el-table-column prop="name" label="编辑">
        <template slot-scope="scope"
          ><el-button
            size="small"
            type="danger"
            @click="delData(scope.row.id)"
            :disabled="scope.row.authStatus == 1 || scope.row.authStatus == 3"
            >删除</el-button
          ></template
        >
      </el-table-column>
    </el-table>
    <pagination
      :limit.sync="queryList.pageSize"
      :page.sync="queryList.pageIdx"
      :total="total"
      @pagination="pagination"
    />
    <el-dialog :visible.sync="showForm" width="600px">
      <el-form
        label-width="160px"
        :model="formList"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item prop="phone" label="入驻手机号">
          <el-input
            style="width: 300px"
            placeholder="单行输入"
            v-model="formList.phone"
            type="number"
            @input="formList.phone = formList.phone.slice(0, 11)"
          ></el-input>
        </el-form-item>
        <el-form-item prop="companyName" label="入驻公司名">
          <el-input
            style="width: 300px"
            placeholder="单行输入"
            v-model="formList.companyName"
            type="text"
          ></el-input>
        </el-form-item>
      </el-form>
      <footer style="width: 100%; text-align: right; margin-top: 50px">
        <el-button @click="showForm = false">取 消</el-button>
        <el-button type="primary" @click="updateEntry">确 定</el-button>
      </footer>
    </el-dialog>
    <el-dialog title="跟单情况" :visible.sync="outerVisible">
      <el-dialog
        width="30%"
        title="新增"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-input
          v-model="feedback"
          placeholder="请输入您反馈的内容"
          style="margin-bottom: 15px"
        ></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="addLook">新 增</el-button>
        </div>
      </el-dialog>
      <el-table
        :data="lookList"
        border
        style="width: 100%; margin-bottom: 10px"
        :header-cell-style="{
          textAlign: 'center',
          color: '#000',
        }"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="followTime" label="反馈时间"> </el-table-column>
        <el-table-column prop="remarks" label="反馈内容"> </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChangeDialog"
          @current-change="handleCurrentChangeDialog"
          :current-page.sync="status.pageIdx"
          :page-sizes="[10, 20, 30, 50]"
          :page-size.sync="status.pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="lookTotal"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVisible = true">新增</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination/index.vue";

import {
  enter_list,
  enter_add,
  enter_list_follow,
  enter_add_follow,
  institution_enter_remove,
} from "../../api/entryInstitution";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      formList: {
        companyName: "",
        phone: "",
      },
      showForm: false,
      feedback: "",
      status: {
        pageIdx: 1,
        pageSize: 10,
        id: "",
      },
      lookTotal: 0,
      outerVisible: false,
      innerVisible: false,
      lookList: [],
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 10, 1),
      queryList: {
        pageIdx: 1,
        pageSize: 10,
        term: "",
        authStatus: "",
      },
      total: 0,
      tableData: [],
      tableHeight:
        (document.documentElement.clientHeight * 0.67).toString() + "px",
      optionState: [
        {
          name: "待注册",
          id: -1,
        },
        {
          name: "已注册",
          id: 2,
        },
        {
          name: "已提交认证",
          id: 1,
        },
        {
          name: "认证成功",
          id: 3,
        },
        {
          name: "认证失败",
          id: 0,
        },
      ],
      rules: {
        phone: [
          { required: true, message: "请输入入驻手机号", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入入驻公司名", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.setTableHeight();
  },
  watch: {
    innerVisible(newval) {
      if (!newval) {
        this.feedback = "";
      }
    },
    outerVisible(newval) {
      if (!newval) {
        this.LoanList();
      }
    },
    showForm(newval) {
      if (!newval) {
        this.formList.phone = "";
        this.formList.companyName = "";
        this.$refs.ruleForm.resetFields();
      }
    },
  },
  methods: {
    delData(data) {
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log();
          institution_enter_remove({
            id: data,
          }).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: `删除成功`,
                type: "success",
              });
              this.LoanList();
            }
          });
        })
        .catch(() => {});
    },
    openDialog() {
      this.showForm = true;
    },
    updateEntry() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          enter_add(this.formList).then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.queryList.pageIdx = 1;
              this.LoanList();
              this.showForm = false;
            }
          });
        }
      });
    },
    addLook() {
      if (!this.feedback) {
        this.$message.error("请输入您反馈的内容");
        return;
      }
      enter_add_follow({
        remarks: this.feedback,
        institutionEnterId: this.status.id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.innerVisible = false;
          enter_list_follow(this.status).then((res) => {
            this.lookList = res.data.records;
            this.lookTotal = res.data.total;
          });
        }
      });
    },
    handleCurrentChangeDialog(pageIdx) {
      this.status.pageIdx = pageIdx;
      enter_list_follow(this.status).then((res) => {
        this.lookList = res.data.records;
        this.lookTotal = res.data.total;
      });
    },
    handleSizeChangeDialog(pageSize) {
      this.status.pageSize = pageSize;
      this.status.pageIdx = 1;
      enter_list_follow(this.status).then((res) => {
        this.lookList = res.data.records;
        this.lookTotal = res.data.total;
      });
    },
    Look(data) {
      this.status.id = data;
      this.outerVisible = true;
      enter_list_follow(this.status).then((res) => {
        this.lookList = res.data.records;
        this.lookTotal = res.data.total;
      });
    },
    LoanList() {
      const dto = this.queryList;
      enter_list(dto).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    inputText() {
      this.queryList.pageIdx = 1;
      if (!this.queryList.term) {
        this.LoanList();
      }
    },
    //设置table高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight =
          (document.documentElement.clientHeight * 0.67).toString() + "px";
      });
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.LoanList();
    },
    //选择条件
    changeCondition() {
      this.queryList.pageIdx = 1;
      this.LoanList();
    },
  },
  created() {
    this.LoanList();
  },
};
</script>

<style scoped>
.content {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.content div {
  float: left;
  margin-left: 100px;
}

.content div p {
  margin-bottom: 10px;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}

::v-deep .el-radio {
  margin-right: 15px;
}

::v-deep .el-radio__label {
  padding-left: 3px;
}

::v-deep thead th .cell {
  color: #000;
  text-align: center;
}

::v-deep tbody td .cell {
  text-align: center;
}

::v-deep .time-select input::-webkit-input-placeholder {
  color: #000;
}

::v-deep .el-range__close-icon {
  width: 100px;
  height: 100px;
  /* background: rgb(95, 94, 94); */
}

.space-line {
  display: inline-block;
  width: 15px;
  text-align: center;
}

.faliedMsg {
  width: 300px;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
  margin-bottom: 0.2703rem;
  display: flex;
  justify-content: center;
}

::v-deep tbody .cell {
  color: #979898;
  font-size: 13px;
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-table thead {
  color: #000;
}
::v-deep thead th {
  background: #f7f7f7;
}
::v-deep tbody td .cell {
  text-align: center;
}

::v-deep .el-table {
  overflow-y: auto;
}

.top {
  margin: 20px 0;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deepinput[type="number"] {
  -moz-appearance: textfield !important;
}

.el-pagination {
  display: flex;
  justify-content: center;
}
</style>
