import request from './request.js';
//列表
export function enter_list(data) {
    return request({
        url: '/institution-enter/list',
        method: 'get',
        params: data
    })
}

//新增或者编辑机构录入
export function enter_add(data) {
    return request({
        url: '/institution-enter/add',
        method: 'POST',
        data
    })
}

//跟单记录
export function enter_list_follow(data) {
    return request({
        url: '/institution-enter/list-follow',
        method: 'get',
        params: data
    })
}

//添加跟单
export function enter_add_follow(data) {
    return request({
        url: '/institution-enter/add-follow',
        method: 'POST',
        data
    })
}

//删除
export function institution_enter_remove(data) {
    return request({
        url: '/institution-enter/remove',
        method: 'get',
        params: data
    })
}